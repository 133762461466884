import React, { HTMLAttributes } from 'react'
import styled from 'styled-components'


interface IContentProps extends HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode
}

const StyledContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  grid-area: content;

  & > div {
    max-width: 1140px;
    padding-bottom: 50px;
  }
`

const Content: React.FC<IContentProps & HTMLAttributes<HTMLDivElement>> = ({
  children
}: IContentProps) => {
  return (
    <StyledContent >
      <div>
        {children}
      </div>
    </StyledContent>
  )
}

export default Content
