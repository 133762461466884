import React, { HTMLAttributes } from 'react'
import { DEFAULT_THEME } from '@zendeskgarden/react-theming'
import styled from 'styled-components'

export interface IBoxProps extends HTMLAttributes<HTMLDivElement> {
  debug?: boolean
  children?: React.ReactNode
}

export interface IStyledBoxProps {
  debug: boolean
}

const StyledBox = styled.div<IStyledBoxProps>`
  ${props => props.debug ? 'background: purple;' : ''}

  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--padding);
  padding: 20px;
`

const Box: React.FC<IBoxProps> = (props: IBoxProps) => {
  const {
    debug = false,
    ...other
  } = props
  return (
    <StyledBox debug={debug} {...other}>
      {props.children}
    </StyledBox>
  )
}

StyledBox.defaultProps = {
  theme: DEFAULT_THEME
}


export default Box
