import { Button } from '@zendeskgarden/react-buttons';
import { ReactComponent as ChevronIcon } from '@zendeskgarden/svg-icons/src/16/chevron-down-stroke.svg';
import React from 'react'
import styled from 'styled-components'
import { useApp } from './app';

const Title = styled.div`
  color: red;
`

export const Home: React.FC = () => {
  const { user } = useApp();

  return (
    <div>
      <div style={{ height: 100 }} />
      <p>{`User ID: ${user ? user.uid : 'no user signed in'}`}</p>
      <Title>Authenticated homepage of user</Title>
      <Button>My button</Button>
      <Button isPrimary>
        Media
        <Button.EndIcon>
          <ChevronIcon />
        </Button.EndIcon>
      </Button>
    </div>
  )
}
