import '@zendeskgarden/css-bedrock/dist/index.css';

import { convertToCSSVariables, overrides } from 'theme';

import { AppProvider } from 'components/app'
import { DefaultSeo } from 'next-seo'
import Head from 'next/head'
import Header from 'components/Header'
import MainLayout from 'components/MainLayout'
import NextApp from 'next/app'
import React from 'react'
import ThemeContainer from 'components/ThemeContainer'
import { createGlobalStyle } from 'styled-components';
import { Content } from 'components/layout';

const GlobalStyle = createGlobalStyle`
  html, body {
    height: 100%;
  }

  body {
    overflow-y: scroll;
    overscroll-behavior-y: none;
  }

  .mainLayout {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: minmax(100px, auto);
    grid-template-areas: 
      "header"
      "content"
      "footer";
  }

  :root {
    ${convertToCSSVariables(overrides)}
  }
`
class App extends NextApp {
  render() {
    const { Component, pageProps } = this.props

    return (
      <>
        <DefaultSeo titleTemplate="%s" />
        <Head>
          <meta name="viewport" content="width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no" />
        </Head>
        <GlobalStyle />
        <ThemeContainer>
          <AppProvider>
            <MainLayout>
              <Header />
              <Content>
                <Component {...pageProps} />
              </Content>
            </MainLayout>
          </AppProvider>
        </ThemeContainer>
      </>
    )
  }
}

export default App
