import React, { ReactNode } from 'react'

import { ThemeProvider } from '@zendeskgarden/react-theming';
import { css } from 'styled-components';
import { overrides } from 'theme';

type Props = {
  children: ReactNode
};


const ThemeContainer: React.FC<Props> = (props: Props) => {
  const { children } = props;
  const theme = (parentTheme: any) => {
    const newTheme = {
      ...parentTheme,
      borderRadii: {
        ...parentTheme.borderRadii,
        md: overrides.borderRadius
      },
      components: {
        'buttons.button': css`
          font-weight: 600;
          `
      },
      colors: {
        ...parentTheme.colors,
        primaryHue: overrides.primaryHue,
        accentHue: overrides.accentHue,
        darkHue: overrides.darkHue,
        contrastHue: overrides.contrastHue,
        foreground: overrides.textColor,
        textHue: overrides.textColor
        // neutralHue: overrides.textColor
      },
      fonts: {
        ...parentTheme.fonts,
        system: overrides.fontFamily,
        secondary: overrides.secondaryFontFamily
      },
      animations: {
        defaultDuration: overrides.animationDefaultDuration,
        defaultCurve: overrides.animationDefaultCurve
      }
    }
    return newTheme
  }

  return (
    <ThemeProvider>
      <ThemeProvider focusVisibleRef={null} theme={theme as any}>
        {children}
      </ThemeProvider>
    </ThemeProvider>
  )
}

export default ThemeContainer
