import Axios, { AxiosRequestConfig } from 'axios'

import { App } from './app';
import { Projects } from './projects'
import { User } from 'models';
import { Users } from './users'
import firebase from 'firebase'

type Config = {
  basePath: string
  firebaseUser: firebase.User | null
  currentUser: User | null
}

export type Pagination = {
  page?: number
  per_page?: number
}

export abstract class Base {
  public Users: Users;
  public Projects: Projects;
  public App: App;
  private readonly basePath: string
  private readonly firebaseUser: firebase.User | null
  private readonly currentUser: User | null

  constructor(config: Config) {
    this.basePath = config.basePath
    this.firebaseUser = config.firebaseUser
    this.currentUser = config.currentUser
    this.Users = new Users(this)
    this.Projects = new Projects(this)
    this.App = new App(this)
  }

  getCurrentUser(): User | null {
    return this.currentUser
  }

  getFirebaseUser(): firebase.User | null {
    return this.firebaseUser
  }

  async request<T>(request: AxiosRequestConfig): Promise<T> {
    const options: AxiosRequestConfig = {
      baseURL: this.basePath,
      timeout: 10000
    }

    if (this.firebaseUser) {
      let token = await this.firebaseUser.getIdToken()
      options.headers = {
        Authorization: `Bearer ${token}`
      }
    }

    const client = Axios.create(options)

    // TODO add custom interceptors
    return client.request(request)
  }
}
