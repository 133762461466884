import { DEFAULT_THEME } from '@zendeskgarden/react-theming'
import React, { HTMLAttributes } from 'react'
import styled, { css } from 'styled-components'
import { IDirection } from '.'

export type IStackSize = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'zero'
export type IStackGravity = 'start' | 'center' | 'end'

export interface IStackProps extends HTMLAttributes<HTMLDivElement> {
  tag?: any
  children: React.ReactNode
  debug?: boolean
  direction?: IDirection
  gravity?: IStackGravity
  spacing?: IStackSize
  wrap?: boolean
}

export interface IStyledStackProps {
  debug: boolean
  tag: any
  direction: IDirection
  gravity: IStackGravity
  spacing: IStackSize
  wrap?: boolean
}

const getSpacing = (theme: any, spacing: IStackSize): string => {
  const { space } = theme
  if (spacing === 'zero') {
    return '0'
  }

  return space[spacing] || space.xs
}

const getGravity = (gravity: IStackGravity): string => {
  switch (gravity) {
    case 'start':
      return 'justify-content: flex-start;'
    case 'center':
      return 'justify-content: center;'
    case 'end':
      return 'justify-content: flex-end;'
    default:
      return 'justify-content: flex-start;'
  }
}

const StyledStack = styled.div<IStyledStackProps>`
  // if debug add color
  ${props => props.debug ? 'background: var(--primaryHue);' : ''}

  display: flex;
  ${props => {
    return css`
      flex-wrap: ${props.wrap ? 'wrap' : 'nowrap'};
    `
  }}

  ${props => {
    if (['h', 'horizontal'].includes(props.direction)) {
      return `
        flex-direction: row;
        ${getGravity(props.gravity)}
        & > * + * {
          margin-left: ${getSpacing(props.theme, props.spacing)} !important;
        } 
      `
    }

    return `
      flex-direction: column;
      ${getGravity(props.gravity)}

      & > * + * {
        margin-top: ${getSpacing(props.theme, props.spacing)} !important;
      } 
      
    `
  }}

`
StyledStack.defaultProps = {
  theme: DEFAULT_THEME
}

const Stack: React.FC<IStackProps> = (props: IStackProps) => {
  const {
    debug = false,
    direction = 'vertical',
    gravity = 'start',
    spacing = 'lg',
    tag = 'div',
    wrap,
    ...rest
  } = props

  return (
    <StyledStack
      debug={debug}
      as={tag}
      direction={direction}
      gravity={gravity}
      spacing={spacing}
      wrap={wrap ? true : undefined}
      {...rest}
    >
      {props.children}
    </StyledStack>
  )
}

Stack.defaultProps = {
  direction: 'vertical',
  gravity: 'start',
  spacing: 'lg',
  debug: false,
  wrap: false
}


export default Stack
