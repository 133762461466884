import {
  Body,
  Chrome as ChromeRaw
} from '@zendeskgarden/react-chrome'

/* eslint-disable react/prop-types */
import React from 'react'
import styled from 'styled-components'

const Chrome = styled(ChromeRaw)`
  overflow-y: auto;
  min-height: 100vh;
  height: 100%;
`

export const MainLayout: React.FC = (props) => {
  return (
    <Chrome isFluid>
      <Body hasFooter style={{ width: '100%' }}>
        {props.children}
      </Body>
    </Chrome>
  )
}

export default MainLayout
