import React from 'react'

export const UnauthenticatedHome: React.FC = () => {
  return (
    <div>
      <div style={{ height: 100 }} />
      Unauthenticated view of the homepage
    </div>
  )
}
