/* eslint-disable @typescript-eslint/restrict-template-expressions */
const ConvertToCSSVariables = (overrides: any) => {
  return Object.keys(overrides).map((key: string) => {
    return `
      --${key}: ${overrides[key]};
    `
  }).join('\n')
}

export default ConvertToCSSVariables
