import React, { createContext, useContext, useEffect, useState } from 'react';

import API from 'api'
import { User } from 'models';
import { firebaseClient } from './firebase/firebaseClient';
import nookies from 'nookies';

const basePath = process.env.NEXT_PUBLIC_API_BASEPATH ?? ''
let newApi = new API({ basePath: basePath, firebaseUser: null, currentUser: null })


const AppContext = createContext<{
  user: firebaseClient.User | null
  api: API
}>({
  user: null,
  api: newApi
});

export var AppProvider = ({ children }: any) => {
  const [user, setUser] = useState<firebaseClient.User | null>(null);
  const [api, setApi] = useState<API>(newApi)

  useEffect(() => {
    let userFromStorage: User = JSON.parse(nookies.get(undefined, {}).user ?? '{}')
    return firebaseClient.auth().onIdTokenChanged(async (u) => {
      console.log('auth changed');
      console.log(u ? u.uid : 'NO USER');

      if (!u) {
        setUser(null);
        nookies.set(undefined, 'token', '', {});
        let a = new API({ basePath: basePath, firebaseUser: null, currentUser: null })
        setApi(a)
        return;
      }

      const token = await u.getIdToken();
      setUser(u);
      let a = new API({ basePath: basePath, firebaseUser: u, currentUser: userFromStorage })
      setApi(a)
      nookies.set(undefined, 'token', token, {});
    });
  }, []);

  return (
    <AppContext.Provider value={{ user, api }}>{children}</AppContext.Provider>
  );
}

export const useApp = () => {
  return useContext(AppContext);
};
