import { DEFAULT_THEME } from '@zendeskgarden/react-theming'
import React, { HTMLAttributes } from 'react'
import styled, { css } from 'styled-components'
import { IDirection } from '.'

type Align = 'normal' | 'center' | 'start' | 'end' | 'flex-start' | 'flex-end'
type Justify = 'normal' | 'center' | 'start' | 'end' | 'flex-start' | 'flex-end' | 'left' | 'right' | 'space-between' | 'space-around' | 'space-evenly' | 'stretch'
interface IFlexProps extends HTMLAttributes<HTMLDivElement> {
  direction?: IDirection
  justify?: Justify
  align?: Align
  fit?: boolean
  wrap?: boolean
}

const StyledFlex = styled('div') <IFlexProps>`
  ${props => {
    return css`
      display: flex;
      flex-direction: ${props.direction === 'vertical' ? 'column' : 'row'};
      justify-content: ${props.justify};
      align-items: ${props.align};
      position: relative; 
      flex-wrap: ${props.wrap ? 'wrap' : 'nowrap'};
      ${props.fit ? css`
          width: 100%;
          height: 100%;
        ` : ''
      }
    `
  }}
`

StyledFlex.defaultProps = {
  theme: DEFAULT_THEME
}

const Flex: React.FC<IFlexProps & HTMLAttributes<HTMLDivElement>> = ({
  direction,
  justify,
  align,
  fit,
  wrap,
  ...other
}: IFlexProps) => {
  return (
    <StyledFlex
      direction={direction}
      justify={justify}
      align={align}
      fit={fit}
      wrap={wrap ? true : undefined}
      {...other}
    />
  )
}

Flex.defaultProps = {
  direction: 'horizontal',
  align: 'normal',
  justify: 'normal',
  fit: true,
  wrap: false
}

export default Flex
