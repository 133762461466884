import { NextPage, NextPageContext } from 'next'

import { Home } from 'components/Home'
import { NextSeo } from 'next-seo'
import React from 'react'
import { UnauthenticatedHome } from 'components/UnathenticatedHome'
import { useApp } from 'components/app'

const IndexPage: React.FC<Record<string, unknown>> & NextPage<Record<string, unknown>> = () => {
  const { user } = useApp()
  return (

    <>
      <NextSeo title="Home" description="" />
      {user && <Home />}
      {!user && <UnauthenticatedHome />}
    </>
  )
}

IndexPage.getInitialProps = async (_ctx: NextPageContext) => {
  return {}
}

export default IndexPage
