import { firebaseClient, githubProvider } from 'components/firebase/firebaseClient'

import API from 'api'
import { NextRouter } from 'next/router';
import { UserSignupData } from 'models';
import nookies from 'nookies';

export class App {
  api: API

  constructor(api: API) {
    this.api = api
  }

  async signin(router: NextRouter) {
    try {
      let credential: firebaseClient.auth.OAuthCredential;

      let firebaseResult = await firebaseClient.auth().signInWithPopup(githubProvider)
      if (!firebaseResult || !firebaseResult.credential) {
        return
      }

      credential = firebaseResult.credential

      let userSignupData: UserSignupData = {
        firebaseId: '',
        githubToken: credential.accessToken ?? ''
      }
      console.log('cred:', firebaseResult.credential)

      let result = await this.api.Users.signUp(userSignupData)
      nookies.set(undefined, 'user', JSON.stringify(result.data.result.user), {})
      await router.push('/')
      console.log('cred:', firebaseResult.credential)
    } catch (e: unknown) {
      console.log(e)
    }
  }

  async signout() {
    await firebaseClient.auth().signOut()
    nookies.destroy(undefined, 'token')
    nookies.destroy(undefined, 'user')
  }
}
