// import Link from 'next/link'
import { Dropdown, HeaderItem as DropdownHeaderItem, Item, Menu, Separator, Trigger } from '@zendeskgarden/react-dropdowns';
import {
  Header as HeaderContainerRaw,
  HeaderItem,
  HeaderItemIcon,
  HeaderItemText
} from '@zendeskgarden/react-chrome'

import { Avatar } from '@zendeskgarden/react-avatars';
import { ReactComponent as HelpIcon } from '@zendeskgarden/svg-icons/src/16/lifesaver-stroke.svg';
import { ReactComponent as MenuTrayIcon } from '@zendeskgarden/svg-icons/src/16/grid-2x2-stroke.svg';
import { PALETTE } from '@zendeskgarden/react-theming';
import { ReactComponent as ProductIcon } from '@zendeskgarden/svg-icons/src/26/garden.svg';
import React from 'react'
import styled from 'styled-components';
import { useApp } from './app';

const HeaderContainer = styled(HeaderContainerRaw)`
  /* position: fixed; */
  z-index: 300;
  width: 100%;
  grid-area:header;
  height: 100px;
`

const Header: React.FC = () => {
  const { user, api } = useApp();

  const signout = async () => {
    await api.App.signout()
  }

  const onMenuSelect = async (item: string) => {
    switch (item) {
      case 'signout':
        await signout()
        break
      default:
        console.log(item)
    }
  }

  return (
    <HeaderContainer isStandalone>
      <HeaderItem hasLogo>
        <HeaderItemIcon>
          <ProductIcon style={{ color: PALETTE.green[400] }} />
        </HeaderItemIcon>
        <HeaderItemText>Zendesk Garden</HeaderItemText>
      </HeaderItem>
      <HeaderItem>
        <HeaderItemIcon>
          <HelpIcon />
        </HeaderItemIcon>
        <HeaderItemText>Help Center</HeaderItemText>
      </HeaderItem>
      <HeaderItem>
        <HeaderItemIcon>
          <MenuTrayIcon />
        </HeaderItemIcon>
        <HeaderItemText isClipped>Products</HeaderItemText>
      </HeaderItem>
      {user && (
        <HeaderItem>
          <Dropdown onSelect={onMenuSelect}>
            <Trigger>
              <Avatar backgroundColor={user.photoURL ? 'transparent' : PALETTE.grey[600]}>
                <>
                  {user.photoURL && <img alt="image avatar" src={user.photoURL} />}
                  {!user.photoURL && <Avatar.Text>ZD</Avatar.Text>}
                </>
              </Avatar>
            </Trigger>
            <Menu>
              <DropdownHeaderItem>
                {user.displayName}
              </DropdownHeaderItem>
              <Separator />
              <Item value="acacia">Acacia</Item>
              <Item value="daisy">Daisy</Item>
              <Item value="signout">Sign out</Item>
            </Menu>
          </Dropdown>
        </HeaderItem>

      )}
    </HeaderContainer >
  )
}

export default Header
