import { User, UserSignupData } from 'models'

import API from 'api'
import { AxiosResponse } from 'axios'

export class Users {
  api: API
  constructor(api: API) {
    this.api = api
  }

  getCurrentUser(): User | null {
    return this.api.getCurrentUser()
  }

  async signUp(userSignupData: UserSignupData) {
    return this.api.request<AxiosResponse>({
      method: 'POST',
      url: 'users/sign-up',
      data: userSignupData
    })
  }

  async setGithubToken(githubToken: string) {
    return this.api.request<AxiosResponse>({
      method: 'POST',
      url: 'users/set-token',
      data: {
        githubToken
      }
    })
  }

  // protected ConvertFromFirebase(_u: firebase.User): User {
  //   let user: User = {
  //   }
  //   return user
  // }
}
