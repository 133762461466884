import firebaseClient from 'firebase'

if (typeof window !== 'undefined' && !firebaseClient.apps.length) {
  firebaseClient.initializeApp({
    apiKey: 'AIzaSyBrNl-7Ts9W2MAHxi_b8xAqkj8QLCWK2JU',
    authDomain: 'fourtwo-23525.firebaseapp.com',
    databaseURL: 'https://fourtwo-23525.firebaseio.com',
    projectId: 'fourtwo-23525',
    storageBucket: 'fourtwo-23525.appspot.com',
    messagingSenderId: '412792405219',
    appId: '1:412792405219:web:026b43f615d00f5e3dd9d6'
  });
  void firebaseClient.auth().setPersistence(firebaseClient.auth.Auth.Persistence.SESSION);
}

var githubProvider = new firebaseClient.auth.GithubAuthProvider();
// githubProvider.addScope('repo');
githubProvider.addScope('read:org')
githubProvider.addScope('read:user')
githubProvider.addScope('user:email')
githubProvider.addScope('public_repo')


export { firebaseClient, githubProvider };
