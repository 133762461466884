// import { Project } from 'models/Project'
import { ListOpts, Project } from 'models'

import API from '..'
import { AxiosResponse } from 'axios'

type ret = {
  projects: Project[]
  total: number
  error: Error | null
}
export class Projects {
  api: API
  constructor(api: API) {
    this.api = api
  }


  async list(listOpts: ListOpts): Promise<ret> {
    const ret: ret = {
      projects: [],
      total: 0,
      error: null
    }

    const {
      perPage = 10,
      page = 1,
      orderBy = 'github_stars',
      orderDirection = 'desc'
    } = listOpts
    try {
      const result = await this.api.request<AxiosResponse>({
        method: 'GET',
        url: `projects?perPage=${perPage}&page=${page}&orderBy=${orderBy}&orderDirection=${orderDirection}`
      })
      ret.projects = result.data.result.projects
      ret.total = result.data.result.total
    } catch (e: unknown) {
      if (e instanceof Error) {
        console.log(e)
        ret.error = e
      }
    }

    return ret
  }

  async getByFullName(fullName: string): Promise<any> {
    try {
      const result = await this.api.request<AxiosResponse>({
        method: 'GET',
        url: `projects/${fullName}`
      })
      return result
    } catch (e: unknown) {
      console.log(e)
    }
  }
}
