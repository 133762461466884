const overrides = {
  primaryHue: '#0AABBA',
  borderRadius: '4px',
  fontFamily: 'Raleway, sans-serif',
  secondaryFontFamily: '"Open Sans", sans-serif',
  accentHue: '#F3BE00',
  darkHue: '#403B33',
  contrastHue: '#EFEECC',
  textColor: '#403B33',
  animationDefaultCurve: 'cubic-bezier(.22, .14, .14, .96)',
  animationDefaultDuration: '.3s'
}

export default overrides
